module.exports = Object.freeze({
    SITE_NAME: "Mozaik veza",

    //Login
    LOGIN_PAGE_NAME: "Prijava",
    LOGIN_PAGE_SUBTITLE:"Već imaš korisnički račun? Upiši podatke…",
    LOGIN_NAME: "Korisničko ime",
    PASSWORD: "Lozinka",
    PASSWORD_AGAIN: "Ponovljena lozinka",
    LOGIN_NAME_ERROR_REQUIRED: "Korisničko ime je obavezno",
    PASSWORD_ERROR_REQUIRED: "Lozinka je obavezna",
    PASSWORD_ERROR_LENGTH: "Lozinka mora imati barem 8 znakova",
    PASSWORDS_NOT_SAME: "Lozinka i ponovljena lozinka nisu identične",
    LOGIN_ERROR: "Korisničko ime ili lozinka su neispravni",
    LOGIN: "Prijavi se",
    FORGOT_PASSWORD: "Zaboravljena lozinka",

    //VerifyEmail
    VERIFY_EMAIL_PAGE_NAME: "Potvrda email adrese",
    VERIFY_EMAIL_ERROR: "Token za verifikaciju email adrese je neispravan ili je već iskorišten.",
    VERIFY_EMAIL_SUCCESS: "Email je uspješno verificiran!",

    //UserPAsswordToken (password change)
    USER_PASSWORD_TOKEN_PAGE_NAME: "Promjena lozinke",
    USER_PASSWORD_TOKEN_ERROR_START: "Promjena lozinke nije uspjela, ",
    USER_PASSWORD_TOKEN_ERROR_TOKEN: "token za promjenu lozinke nije ispravan",
    USER_PASSWORD_TOKEN_ERROR_ELSE: "nismo sigurni zašto, molimo prijavite ovu grešku na info@mozaikveza.hr",
    USER_PASSWORD_TOKEN_SEND_AGAIN: "Ponovno mi pošalji mail za promjenu lozinke",
    USER_PASSWORD_TOKEN_FOR: "Promjena lozinke za",
    USER_PASSWORD_PASSWORD_REQUIRED: "Lozinka je obavezno polje",
    ERROR_OCCURRED: "Došlo je do greške",
    USER_PASSWORD_CHANGE: "Promijeni šifru",

    //SignUp
    SIGN_UP_SITE_NAME: "Registracija",
    SIGN_UP_SUBTITLE: "Napravi korisnički račun na Mozaiku veza, brzo i jednostavno.",
    EMAIL: "Email",
    EMAIL_ERROR: "Potrebno je unijeti valjanu email adresu.",
    USER_WITH_USERNAME_EXISTS: "Postoji korisnik s ovim korisničkim imenom.",
    USERNAME_CAN_HAVE_CHAR: "Korisničko ime može sadržavati slova, brojke i znakove.",
    USER_WITH_EMAIL_EXISTS: "Korisnik s ovom email adresom već postoji.",
    EMAIL_NOT_VALID: "Email adresa nije ispravna.",
    SIGN_UP_ERROR: "Došlo je do greške prilikom registracije. Pokušajte ponovno.",
    SIGN_UP: "Registriraj se",
    WITH_PROFILE_YOU_CAN: "UZ PROFIL MOŽEŠ",
    WITH_PROFILE_YOU_CAN_SAVE_ENTITY: "Spremiti entitete",
    WITH_PROFILE_YOU_CAN_SAVE_SEARCH: "Spremiti pretrage",
    WITH_PROFILE_YOU_CAN_GET_NOTIFICATION: "Primati obavijesti o promjeni podataka",
    ALREADY_HAVE_PROFILE: "Imaš profil",
    LOGIN_SMALL_CAPS: "prijavi se",

    //Search
    SEARCH_PAGE_NAME: "Pretraga",
    SEARCH_PLACEHOLDER: "Pretraži fizičke i pravne osobe",
    SEARCH_NUMBER_OF_RESULTS: "Broj rezultata na stranici",


    //all personal pages and modals:
    PERSONAL_PAGE_NAME: "Osobne stranice",
    SAVED_ENTITIES: "Spremljeni entiteti",
    SAVED_ENTITIES_SUBTITLE: "Entiteti koje pratiš — ako ih više ne želiš pratiti, odznači ih klikom na zvjezdicu.",
    ENTITY_SMALLCAPS: "entitet",
    ENTITIES_SMALLCAPS: "entiteta",

    SAVED_SEARCHES: "Spremljene pretrage",

    PERSONAL_DATA: "Osobni podaci",
    PERSONAL_DATA_SUBTITLE: "Ovi podaci nisu obavezni za korištenje Mozaika veza",
    PERSONAL_DATA_NAME: "Ime",
    PERSONAL_DATA_LAST_NAME: "Prezime",
    PERSONAL_DATA_WANT_TO_RECIEVE_EMAILS: "Želim primati obavijesti o entitetima koje pratim",
    PERSONAL_DATA_EMAIL_CHANGED: "Promijene su uspješno spremljene. Ako ste promijenili email adresu dobit ćete email poruku za verifikaciju nove adrese.",
    PERSONAL_DATA_EMAIL_ERROR: "Promjene nisu spremljene.",
    PERSONAL_DATA_EMAIL_ERROR_NAME_TO_LONG: " može sadržavati najviše 30 znakova.",
    PERSONAL_DATA_EMAIL_ERROR_EMAIL_EXISTS: " ova adresa se već koristi.",
    PERSONAL_DATA_EMAIL_ERROR_EMAIL_TO_LONG: " ova adresa je preduga, pokušajte s adresom do 254 znaka.",
    PERSONAL_DATA_CHANGE_PASSWORD: "Promijeni lozinku",

    SAVE_SEARCH: "Spremi pretragu",
    SAVED_SEARCH_NAME: "Unesite ime za ovu pretragu",
    SAVED_SEARCH_NAME_ERROR_LENGHT: "Ime može imati najviše 64 znaka",
    SAVED_SEARCH_NAME_ERROR_REQUIRED: "Ime pretrage je obavezno",
    SAVED_SEARCH_NAME_ERROR_EXISTS: "Ova pretraga je već spremljena",
    SAVED_SEARCH_NAME_ERROR_NAME_EXISTS: "Postoji pretraga s ovim imenom. Pokušajte odabrati drugo ime.",

    ATTR_HISTORY: "Povijest atributa",
    DATE: "Datum",
    COLLECTION: "Kolekcija",
    OLD_VALUE: "Stara vrijednost",
    DURATION: "Trajanje",
    NEW_VALUE: "Nova vrijednost",

    SOURCE_NAME: "Izvor",
    LAST_CHANGE_FROM_COLL: "Zadnja izmjena iz kolekcije",
    OPEN_SOURCE: "Otvori izvore",
    TRANSACTION_AMOUNT: "Vrijednost transakcije",
    TRANSACTION_DATES: "Datum transakcije",
    DETAILS: "Detalji zapisa",
    EXPORT: "Izvoz u JSON",

    PROFIL: "Profil",
    MY_ENTITIES: "Entiteti koje pratim",
    MY_SAVED_SEARCH: "Spremljene pretrage",
    LOGOUT: "Odjava",
    EMAIL_ADDRESS: "Email adresa",
    USER_WITH_USERNAME_DOES_NOT_EXIST: "Ne postoji korisnik s ovom email adresom",
    SEND: "Pošalji",
    NO_FOLLOWED_ENTITIES: "Nema prećenih entiteta",
    NO_SAVED_SEARCHES: "Nema spremljenih pretraga",
    DELETE_SAVE_SEARCH_CONFIRMATION: "Ne trebam više ovu pretragu?",
    DELETE_FOLLOWED_ENTITY_CONFIRMATION: "Više ne želim pratiti ovaj entitet?",
    //end ofall personal pages and modals

    SAVE: "Spremi",
    CLOSE: "Zatvori",
    YES: "da",
    NO: "ne",
    YES_CAPS: "Da",
    NO_CAPS: "Ne",
    ALL: "Sve",
    AND: "i",
    MORE: "Ne prikazuju se svi rezultati",
    TOTAL: "Ukupno postoji",
    PERSON: "Fizička osoba",
    LEGAL_ENTITY: "Pravna osoba",
    REALESTATE: "Nekretnina",
    MOVABLE: "Pokretnina",
    SAVINGS: "Štednja",
    CONNECTION_SMALLCAPS: "veza",
    CONNECTIONS_SMALLCAPS: "veze",
    PROPERTY: "Imovinsko-pravne veze",
    BUSINESS: "Poslovne veze",
    POLITICAL: "Političke veze",
    FAMILY: "Obiteljske veze",
    INTERESTS: "Interesnve veze",
    PROPERTY3: "IMO",
    BUSINESS3: "POS",
    POLITICAL3: "POL",
    FAMILY3: "OBI",
    INTERESTS3: "INT",
    PROPERTY_SMALLCAPS: "imovinsko-pravna",
    PROPERTIES_SMALLCAPS: "imovinsko-pravne",
    BUSINESS_SMALLCAPS: "poslovna",
    BUSINESSES_SMALLCAPS: "poslovne",
    POLITICAL_SMALLCAPS: "politička",
    POLITICALS_SMALLCAPS: "političke",
    FAMILY_SMALLCAPS: "obiteljska",
    FAMILIES_SMALLCAPS: "obiteljske",
    INTEREST_SMALLCAPS: "interesna",
    INTERESTS_SMALLCAPS: "interesne",

    //Home
    ABOUT_PROJECT_SITE_NAME: "O projektu",//name of article from CMS with article slug "about_project"
    HOME_PAGE_WHAT_PEOPLE_OFTEN_SEARCH: "Posjetitelji često pretražuju",
    HOME_PAGE_HOWTO_SUBTITLE: "Kako koristiti Mozaik veza",
    HOME_PAGE_HOWTO_SUB_SUBTITLE: "Pogledaj primjere koje smo pripremili kako bi dobio uvid u napredne mogućnosti\n" +
        "                                    Mozaika veza i svega što ti nudi.",
    HOME_PAGE_START_FIRST_VIDEO: "Pogledaj uvodni video",
    HOME_PAGE_START_SECOND_VIDEO: "Kako koristiti Mozaik veza",

    //FilterConnections
    FILTER_CONNECTIONS_SITE_NAME: "Napredno pretraživanje veza",
    FILTER_CONNECTIONS_IN_DB_WE_HAVE: "U bazi imamo",
    FILTER_CONNECTIONS_PLEASE_USE_FILTER: "Koristite filter kako biste pronašli veze koje vas zanimaju.",
    FILTER_CONNECTIONS_NO_CONNECTIONS: "Nema veza koje zadovoljavaju ovaj kriterij",
    FILTER: "Filter",
    ENTITY: "Entitet",
    CONNECTION: "Veza",
    CONNECTIONS: "Veze",
    SAVE_CONNECTIONS_FILTER_TITLE: "Spremi ovu vizualizaciju",
    SAVE_CONNECTIONS_FILTER: "Spremi ovo pretraživanje",
    FILTER_CONNECTIONS_CATEGORIES: "Kategorije veze",
    DATES: "Vrijeme",
    TRANSACTIONS_AMOUNTS: "Iznos transakcije",
    FROM: "Od...",
    TO: "Do...",
    FILTER_CONNECTIONS_ENTITIES: "Kategorije entiteta",
    VISUALIZATION: "Vizualizacija",
    SHOW_ONLY_PEP: "Prikaži samo PEP",
    PEP_EXPLANATION: "PEP - Politički izložena osoba",
    SEARCH: "Pretraživanje",
    SEARCH_PLACEHOLDER_VISUALIZATION: "Upiši naziv kategorije...",
    VIZUALIZATION_TOOLTIP_ENTITY: "Odaberi za više informacija, dvostruki klik za centriranje",
    VIZUALIZATION_TOOLTIP_CONN: "Odaberi za više informacija",

    //Filter
    VISUALIZATION_FROM: "od",
    VISUALIZATION_ENTITIES: "entiteta",
    TOO_MANY_CONNECTIONS: "Previše je tipova veza. Pomoću pretraživanja pronađite tipove veza koje želite.",
    START_SEARCH: "Upiši naziv kategorije...",
    OPEN_ENTITY: "Otvori entitet",
    OPEN_CONNECTION: "Otvori vezu",

    //pagination
    PAGINATION_PREVIOUS: "Prethodna",
    PAGINATION_NEXT: "Sljedeća",

    //Collection
    COLLECTION_SITE_NAME: "Izvori",
    COLLECTION_LAST_CHANGE: "Zadnja promjena",
    COLLECTION_DESCRIPTION: "Opis",
    COLLECTION_TYPE: "Tip kolekcije",

    //Connection

    //404 page
    PAGE_NOT_FOUND : "Stranica nije pronađena.",
    PAGE_NOT_FOUND_ENTITY : "Ovaj entitet nije pronađen.",
    PAGE_NOT_FOUND_CONNECTION : "Ova veza nije pronađena.",
    PAGE_NOT_FOUND_ARTICLE : "Ovaj članak nije pronađen.",
    HOME_PAGE: "Naslovnica",

    //Footer
    CREATIVE_COMMONS: "Creative Commons 2019. ",
    TEARM_OF_USE: "Uvjeti korištenja",
    PRIVACY_POLICY: "Pravila privatnosti ",
    REPORT_ERROR: "Prijava grešaka",
    CONTACT: "Kontakt",
    EU: "Sufinancira Europska unija u okviru programa Europa za građane",
    GOOGLE_PART1: "On-line alat razvijen je uz financijsku podršku Google-ovog",
    GOOGLE_PART2: "Europske unije kroz program",
    GOOGLE_PART3: "i Nacionalne zaklade za razvoj civilnog društva kroz program",
    US_EMBASSY: "Veleposlanstva SAD-a u Zagrebu",
    GONG: "je Centar znanja u području građanskog aktivizma i izgradnje demokratskih institucija društva u okviru Razvojne suradnje s Nacionalnom zakladom za razvoj civilnog društva",
    BACKTOTOP: "Povratak na vrh",

    //AboutShort
    ABOUTSHORT_PERSONS: "fizičkih osoba",
    ABOUTSHORT_LEGAL: "pravnih osoba",
    ABOUTSHORT_READ_MORE: "Pročitaj više",

    //Entity
    START_FILTER :'Pokreni vizualizaciju',
    INFO: "Informacije",
    DATA: "podatak",
    DATA_DUAL: "podatka",
    DATA_PLURAL: "podataka",
    RATIO: "Omjeri",
    YEARS: "Godine",
    FOLLOW_ENTITY: "Prati entitet",
    STOP_FOLLOWING_ENTITY: "Prestani pratiti entitet",
    SHOW_MORE: "Prikaži još ",
    SHOW: "Prikaži ",
    SORT: "Sortiraj ",
    DEFAULT: "Zadano",
    CONN_LIST: "Popis veza",
    VALID_FROM: "Vrijeme od",
    VALID_FROM_ASC: "Vrijeme od, uzlazno",
    VALID_FROM_DESC: "Vrijeme od, silazno",
    VALID_TO: "Vrijeme do",
    VALID_TO_ASC: "Vrijeme do, uzlazno",
    VALID_TO_DESC: "Vrijeme do, silazno",
    AMOUNT: "Iznos",
    AMOUNT_ASC: "Iznos, uzlazno",
    AMOUNT_DESC: "Iznos, silazno",
    RELATED_ENTITIES: "Povezani entiteti",

    //DayPicker
    MONTHS: [
        'Siječanj',
        'Veljača',
        'Ožujak',
        'Travanj',
        'Svibanj',
        'Lipanj',
        'Srpanj',
        'Kolovoz',
        'Rujan',
        'Listopad',
        'Studeni',
        'Prosinac',
    ],
    DAYS: ['ned', 'pon', 'uto', 'sri', 'čet', 'pet', 'sub'],
    NEXT: "sljedeći",
    PREVIOUS: "prethodni"
});