export const username = '';
export const password = '';

export const default_url = "https://backend.mozaikveza.hr/api";
export const ga_trackingID = "";
export const number_per_page = 20;
export const number_per_page_filter = 30;//number of entities per page for visualization
export const number_per_page_by_db = 100;//number of results limited in backend
//ARTICLES:
export const about_project = "o_projektu";//article slug, for article linked in header, and short for right column (AboutShort.js), also on main page
export const about_project_name = "O Projektu";//about_project article name for header
export const home_top = "naslovnica_1";//article slug, for article on the main page on yellow background. Content long is NOT used anywhere
export const home_first = "naslovnica_2";//article slug, for article on the main page, first of three pictures that explain parts of app. Content long is NOT used anywhere
export const home_second = "naslovnica_3";//article slug, for article on the main page, second of three pictures that explain parts of app. Content long is NOT used anywhere
export const home_third = "naslovnica_4";//article slug, for article on the main page, third of three pictures that explain parts of app. Content long is NOT used anywhere
export const sources = "izvori_podataka";//article slug, for article on the collections page, above list of collections and sources. Content long is NOT used anywhere
export const terms = "uvjeti_koristenja";//article slug, for article linked in footer
export const privacy = "pravila_privatnosti";//article slug, for article linked in footer
//HOME PAGE
export const home_person_slug = "andrej-plenkovic";//entity slug, for links on home page (second and third picture link)
export const about_video_url = "https://www.youtube.com/embed/eX7_gzdaRZU";
export const how_to_video = "https://www.youtube.com/embed/YJJx-cdbdDg";

//HOME PAGE OFTEN SEARCH ENTITIIES:
export const entity_first = "andrej_plenkovic";
export const entity_first_name = "Andrej Plenković";
export const entity_second = "zoran_milanovic";
export const entity_second_name = "Zoran Milanović";

//FOOTER:
export const bug_link = "https://github.com/Gong-hr/mozaik-veza/issues/new?template=prijava-problema-bugova.md";
export const contact_mail = "info@mozaikveza.hr";

export const allowedTags = ['a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ol', 'ul', 'li', 'pre', 'address', 'div', 'em', 'strong', 'p'];
export const allowedAttributes = {
    'h1': ['style'],
    'h2': ['style'],
    'h3': ['style'],
    'h4': ['style'],
    'h5': ['style'],
    'h6': ['style'],
    'div': ['style'],
    'p': ['style'],
    'a': ['href', 'style', 'target'],
    'ol': ['style'],
    'ul': ['style'],
    'li': ['style'],
    'pre': ['style'],
    'address': ['style'],
    'em': ['style'],
    'strong': ['style']
};
